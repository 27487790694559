import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`DISCLAIMER`}</strong>{`: this roadmap is subject to change.`}</p>
    <p>{`This roadmap loosely tracks `}<a parentName="p" {...{
        "href": "https://github.com/orgs/fullcalendar/projects/1"
      }}>{`FullCalendar Planning spreadsheet`}</a>{`.
FullCalendar `}<a parentName="p" {...{
        "href": "https://github.com/fullcalendar/fullcalendar/milestone/72"
      }}>{`bugfixes`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/fullcalendar/fullcalendar/milestone/77"
      }}>{`minor features`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://github.com/fullcalendar/fullcalendar/issues?q=is%3Aissue+is%3Aopen+performance+label%3APerformance"
      }}>{`performance improvements`}</a>{` and will be released throughout each quarter.`}</p>
    <p><strong parentName="p">{`2024 - Q4`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`FullCalendar `}<a parentName="p" {...{
            "href": "https://github.com/fullcalendar/fullcalendar/milestone/51"
          }}>{`v7.0.0 stable`}</a>{`, follow-up to `}<a parentName="p" {...{
            "href": "https://github.com/fullcalendar/fullcalendar-workspace/releases/tag/v7.0.0-beta.0"
          }}>{`v7.0.0-beta.0`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Accessibility improvements`}</li>
          <li parentName="ul">{`Restore print-mode`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`FullCalendar `}<a parentName="p" {...{
            "href": "https://github.com/fullcalendar/fullcalendar/milestone/73"
          }}>{`v7.1`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Virtual rendering for resources/dates/times/events`}</li>
          <li parentName="ul">{`Resource Timeline view, full responsiveness`}</li>
          <li parentName="ul">{`Vertical Resource views, resource grouping`}</li>
          <li parentName="ul">{`Highlight cells on hover`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Website`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Refactor docs to use same metaframework as top-level site (`}<a parentName="li" {...{
              "href": "https://github.com/fullcalendar/fullcalendar/issues/6221"
            }}>{`#6221`}</a>{`)`}</li>
          <li parentName="ul">{`Improved responsiveness`}</li>
          <li parentName="ul">{`Improved demos page`}</li>
          <li parentName="ul">{`Dark mode for docs`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/fullcalendar/temporal-polyfill"
          }}>{`temporal-polyfill`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Update to latest spec (July 2024)`}</li>
          <li parentName="ul">{`New website (a guide to the spec, how to use the polyfill)`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2025 - Q1`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/74"
        }}>{`v8`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Infinite scroll for all views`}</li>
          <li parentName="ul">{`Resource Timeline view, resource pagination`}</li>
          <li parentName="ul">{`Hijri and Jalaali calendar systems`}</li>
          <li parentName="ul">{`TypeScript definition improvements`}</li>
          <li parentName="ul">{`Restore dual rendering for React/Preact`}</li>
          <li parentName="ul">{`Internal data refactor to leverage (P)react state instead of unweildy OOP data stores`}</li>
          <li parentName="ul">{`Internal usage of `}<a parentName="li" {...{
              "href": "https://github.com/fullcalendar/temporal-polyfill"
            }}>{`temporal-polyfill`}</a>{` (eliminates need for timezone plugins)`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2025 - Q2`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/75"
        }}>{`v9`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Swipe/sliding effect when navigating prev/next`}</li>
          <li parentName="ul">{`Navigate prev/next when dragging event to edges`}</li>
          <li parentName="ul">{`Advanced accessibility interactions (roving tab index, date select)`}</li>
          <li parentName="ul">{`Composable components such as `}<inlineCode parentName="li">{`<Toolbar>`}</inlineCode>{` and `}<inlineCode parentName="li">{`<MonthView>`}</inlineCode>{` for React only`}</li>
          <li parentName="ul">{`Deep integration with React UI frameworks like MUI, Chakra, and Mantine`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2025 - Q3`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/76"
        }}>{`v10`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Full separation of calendar state from view layer, enabling:`}
            <ul parentName="li">
              <li parentName="ul">{`Real Vue/Angular components as opposed to an embedded Preact adapters`}</li>
              <li parentName="ul">{`Svelte/SolidJS implementations that match each framework's impressive speed`}</li>
              <li parentName="ul">{`Composable components such as `}<inlineCode parentName="li">{`<Toolbar>`}</inlineCode>{` and `}<inlineCode parentName="li">{`<MonthView>`}</inlineCode>{` for all frameworks`}</li>
              <li parentName="ul">{`Headless UI library (TBD)`}
                {/* package rearrangement */}
                {/* how styles are imported, nonce problems */}
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2025 - Q4`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/76"
        }}>{`v10`}</a>{` (cont'd)`}
        <ul parentName="li">
          <li parentName="ul">{`Building on the refactoring from Q3, a React Native component (TBD)`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      